// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Publiclandingpage from "../../blocks/publiclandingpage/src/Publiclandingpage";
import Coursepreviewpage from "../../blocks/coursepreviewpage/src/Coursepreviewpage";
import Allcoursespage from "../../blocks/allcoursespage/src/Allcoursespage";
import Dashboard6 from "../../blocks/dashboard6/src/Dashboard6";
import Certificationlistingpage from "../../blocks/certificationlistingpage/src/Certificationlistingpage";
import Quize from "../../blocks/quize/src/Quize";
import Searchfeatureforlms from "../../blocks/searchfeatureforlms/src/Searchfeatureforlms";
import Coursecontentvideo from "../../blocks/coursecontentvideo/src/Coursecontentvideo";
import Googlessologinpopup from "../../blocks/googlessologinpopup/src/Googlessologinpopup";
import Coursecontentarticle from "../../blocks/coursecontentarticle/src/Coursecontentarticle";



const routeMap = {
Publiclandingpage:{
 component:Publiclandingpage,
path:"/Publiclandingpage"},
Coursepreviewpage:{
 component:Coursepreviewpage,
path:"/Coursepreviewpage"},
Allcoursespage:{
 component:Allcoursespage,
path:"/Allcoursespage"},
Dashboard6:{
 component:Dashboard6,
path:"/Dashboard6"},
Certificationlistingpage:{
 component:Certificationlistingpage,
path:"/Certificationlistingpage"},
Quize:{
 component:Quize,
path:"/Quize"},
Searchfeatureforlms:{
 component:Searchfeatureforlms,
path:"/Searchfeatureforlms"},
Coursecontentvideo:{
 component:Coursecontentvideo,
path:"/Coursecontentvideo"},
Googlessologinpopup:{
 component:Googlessologinpopup,
path:"/Googlessologinpopup"},
Coursecontentarticle:{
 component:Coursecontentarticle,
path:"/Coursecontentarticle"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;